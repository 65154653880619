import React from 'react';
import '../CSS/VideoSection.css';

const VideoSection = () => {
  const videoId = 'pOcy6I3DaZw'; // YouTube Video ID
  const videoUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    <section className="video-section">
      <iframe
        className="youtube-video"
        src={videoUrl}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </section>
  );
};

export default VideoSection;