import React, { useState } from 'react';
import PayPalScriptLoader from './Sections/Components/PayPalScriptLoader'
import Sidebar from './Sections/Sidebar';
import Home from './Sections/Home';
import About from './Sections/About';
import VideoSection from './Sections/VideoSection';
import Events from './Sections/Events';
import Footer from './Sections/Footer';
import Gallery from './Sections/Gallery';
import Merch from './Sections/Merch';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  const [isPayPalScriptLoaded, setIsPayPalScriptLoaded] = useState(false);

  return (
    <div className="App d-flex">
      <PayPalScriptLoader
        clientID="Afgv-rOjpoWac9KyoxQGczqHpBvTz9lEIz-RBlArs-RyEbwhDhoX-mNeBlHUKT5rmL9BQwb1tvuvRKEb"
        onLoad={() => setIsPayPalScriptLoaded(true)}
      />
      <Sidebar isPayPalScriptLoaded={isPayPalScriptLoaded}/>
      <div className="content">
        <div className="container-fluid main-content">
          <div className="row">
            <div className="col">
              <Home />
            </div>
          </div>
          <div className="row">
            <div className="col">    
              <Gallery />
            </div>
          </div> 
          <div className="row">
            <div className="col">
              <About />
            </div>
          </div>     
          <div className="row">
            <div className="col">
              <Events />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Merch />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <VideoSection />
            </div>
          </div> 
        </div>
        <div className="row footer">
          <div>
            <Footer />
          </div>
        </div> 
      </div>
    </div>
  );
}

export default App;