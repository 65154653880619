import React, { useEffect } from 'react';

const loadPayPalScript = (clientID, callback, onError) => {
  const scriptUrl = `https://www.paypal.com/sdk/js?client-id=${clientID}&components=hosted-buttons&enable-funding=venmo&currency=USD`;

  // Check if the script is already included in the page
  const existingScript = document.querySelector(`script[src="${scriptUrl}"]`);

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = scriptUrl;
    script.async = true;
    script.onload = () => {
      console.log("PayPal SDK script loaded");
      if (typeof callback === 'function') callback();
    };
    script.onerror = () => {
      console.error("PayPal SDK script failed to load");
      if (typeof onError === 'function') onError();
    };
    document.body.appendChild(script);
  } else {
    console.log("PayPal SDK script already loaded");
    if (typeof callback === 'function') callback();
  }
};

const PayPalScriptLoader = ({ clientID, onLoad, onError }) => {
  useEffect(() => {
    loadPayPalScript(clientID, onLoad, onError);
  }, [clientID, onLoad, onError]);

  return null; // This component does not render any UI
};

export default PayPalScriptLoader;