import React from 'react';
import { Nav } from 'react-bootstrap';

const About = () => {
  return (
    <div id="about">
      <h2>About Us</h2>
      <h5>
        Westchester NY's powerhouse cover band, 'What's In The Box' brings the energy and authentic spirit of the 90s alternative rock scene to life. Since forming in October 2023, this 5-piece ensemble has been dialing in the defining sounds of the era. Frontman Pete Repole's raw vocals and the dynamic guitar duo of PJ Angarano and Shane Labriola capture the essence of legends like Soundgarden, Alice In Chains, and STP. The rhythm section, with Gavin Reign on bass and Steve DeLitta on drums, delivers depth and drive. Every performance is a nostalgic journey through the gritty anthems that shaped a generation. We are What's In The Box. A tribute to the raw, unapologetic spirit of 90s alternative music.
      </h5>
      
    </div>
  );
};

export default About;