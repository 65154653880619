import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyD5wAQj-GXErIClaGTqCotnwuFGPylCIp4",
    authDomain: "witb-2024.firebaseapp.com",
    projectId: "witb-2024",
    storageBucket: "witb-2024.appspot.com",
    messagingSenderId: "858784863934",
    appId: "1:858784863934:web:429abbcca72760698eb0ef",
    measurementId: "G-C8G1W2MJNH"
  };
  
  const app = initializeApp(firebaseConfig);

  // Get a reference to the storage service
  const storage = getStorage(app);
  
  export { storage };